:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #242d49;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  --buttonBg: linear-gradient(98.63deg, #f9a225 0%, #f95f35 100%);
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4CB256;
  --video: #4A4EB7;
  --location: #EF5757;
--shedule: #E1AE4A;
}

.App{
  overflow: hidden;
  color: var(--black);
  background: #f3f3f3;
  padding: 1rem 1rem;
}

.blur{
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #a6ddf0;
  filter: blur(72px);
}


.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: var(--buttonBg);
  transition: all 100ms ease-out;
}
.btn:hover{
  cursor: pointer;
  color:var(--orange);
  background:transparent ;
  border:2px solid var(--orange);
  transform:scale(1.01);
}

::-webkit-scrollbar{
  display: none;
}