

.rightside{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    


}
.nav-icon{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    /* padding: 1.5rem; */
    margin-right: 50px;
}
.nav-icon>img{
    width:2.5rem;
    height: 2.5rem;
}
.r-btn{
    height: 3rem;
    width: 70%;
    align-self: center;
    font-size: 20px;
    transition: transform 0.3 ease-in;
   
}
.r-btn:hover{
    transform: scale(1.1);

}