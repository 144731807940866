

.postshare{
    display: flex;
    gap: 1rem;
    background-color: var(--cardColor);
    padding: 1rem;
    border-radius: 1rem;
}

.postshare>img{
    border-radius: 50%;
    width: 4rem;
    height: 4rem;

}
.postshare>div{
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 1rem;
}
.postshare>div>input{
    background-color: var(--inputColor) ;
    border-radius: 10px;
    padding: 16px;
    font-size: 17px;
    border:none;
    outline:none;

}
.postoptions{
    display: flex;
    justify-content: space-around;


}
.option{
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
  display: flex;
  align-items: center;justify-content: center;
  font-size: 20px;
}
.option:hover{
    cursor: pointer;
}

.ps-btn{
    padding: 5px;
    padding-left: 20px;
    font-size: 12px;
    padding-right: 20px;
}
.previewimage{
    position: relative;

}
.previewimage>svg{
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
}
.previewimage>img{
    width: 100%;
    max-height: 25rem;
    object-fit: cover;
    border-radius: 0.5rem;

}

