

.logosearch{
    display: flex;
    gap: 0.25rem;
    width: 100%;
    justify-content: space-evenly;

}
.logosearch>img{
    padding: 0.5rem;
}
.search{
    display: flex;
    background-color: var(---inputColor);
    border-radius: 10px;
    padding: 5px;

}
.search>input{
    background-color: #eddede;
    border: none;
    outline: none;
    padding: 1rem;
    width: 100%;
   


}

.s-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background:linear-gradient(106.23deg,#f99827,#f95f35) ;
    border-radius: 5px;
    padding: 10px;
    color: white;

}

.s-icon:hover{
    cursor: pointer;
}