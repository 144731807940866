

.auth{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    gap: 4rem;
    position: relative;
    /* margin-top: 20rem; */
}

.auth>div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.a-left{
    gap: 2rem;
}

.a-left>img{
    width: 4rem;
    height: 4rem;

}
.webname>h1{
    font-size: 3rem;
    background-color: red;
    background-image:var(--buttonBg) ;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color:transparent;
    -moz-background-clip:text;
    -moz-text-fill-color:text;


}
.webname>h6{
    font-size: 1rem;
}


.infoform{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.infoinput{
    border:none;
    outline:none;
    background-color: var(--inputColor);
    border-radius: 8px;
    padding: 20px;
    flex:1;
}

.infoform>div{
    display: flex;
    gap: 1rem;
    height: 2rem;
    width: 90%;
}
.info-btn{
    width: 6rem;
    height: 2rem;
    align-self: flex-end;
}

.authform{
    background-color: var(--cardColor);
    border-radius: 1rem;
    padding: 1rem;

}
.authform>div{
    width: 100%;
    align-items: center;
    justify-content: center;
}
