

.trendcart{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--cardColor);
    padding: 1rem;
    border-radius: 1rem;
    padding-left: 2rem;
    padding: 30px;

}

.trend{
    display: flex;flex-direction: column;
    gap: 0.5rem;

}
.trend>span:nth-of-type(1){
    font-weight: bold;
    font-size: 1.5rem;
}
.trend>span:nth-of-type(2){
    font-size: 16px;
}

