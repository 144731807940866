
.infocard{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--cardColor);
    padding: 0.7rem;
    border-radius: 2rem;
    width: 90%;
    padding: 2rem;
}

.infohead{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.infohead>h4{
    font-size: 1.5rem;
}
.infohead>div:hover{
    cursor: pointer;

}
.logout-btn{
    width: 7rem;
    height: 2.5rem;
    margin-top: 4rem;
    align-self: flex-end;
    padding: 1rem;
}

.info>span:nth-of-type(1){
    font-size: 1.6rem;

}
.info>span:nth-of-type(2){
    font-size: 1.3rem;

}