

.post{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: var(--cardColor);
    border-radius: 1rem;
    gap: 1rem;
}
.post>img{
    width: 100%;
    max-height:35rem;
    object-fit: cover;
    border-radius: 0.5rem;
}

.postreact{
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
}