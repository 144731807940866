

.profile{
    position: relative;
    display: grid;
    grid-template-columns:26rem auto 30rem ;
    gap: 2rem;

}
.profile-center{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
}