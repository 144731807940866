

.followerscard{
    width: 100%;
    border-radius: 0.5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;
   
}
.followerscard>h3{
    font-size: 1.2rem;
}
.follower{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.follower>div{
    display: flex;
    gap: 10px;
}
.followerimg{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}
.name{
    display: flex;flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.name>span:nth-of-type(1){
    font-weight: bold;
    font-size: 1rem;
}

.follow-button{
    height: 2rem;
    padding-left: 20px;
    padding-right: 20px;
    
}